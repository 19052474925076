import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram, faLinkedin, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'
import X_Logo from '../../../static/X_Logo.svg'

import './SocialLinks.scss'

const SocialLinks = () => (
  <div className="social-links">
    <div className="wrapper">
      <h2>Stay Connected on Social Media</h2>
      <div className="links">
        <a
          className="social gtm-outbound-link-social-facebook"
          target="_blank"
          href="https://www.facebook.com/ovationvr/"
        >
          <FontAwesomeIcon icon={faFacebookSquare} />
        </a>
        <a
          className="social gtm-outbound-link-social-linkedin"
          target="_blank"
          href="https://www.linkedin.com/company/11261847/"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a className="social gtm-instagram-social" target="_blank" href="https://www.instagram.com/ovationvr/">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          className="social gtm-outbound-link-social-youtube"
          target="_blank"
          href="https://www.youtube.com/channel/UC3OrW8taSo8kb2ZHxmfY_Ig"
        >
          <FontAwesomeIcon icon={faYoutubeSquare} />
        </a>
        <a className="social" target="_blank" href="https://twitter.com/ovationvr">
          <X_Logo className="svg-x-twitter-icon" />
        </a>
      </div>
    </div>
  </div>
)

export default SocialLinks
