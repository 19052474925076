export const metaData = {
  home: {
    title: 'Ovation: Speak Confidently. From Virtual to Reality.',
    description:
      'Revolutionary software to overcome fear of public speaking, refine presentation skills, ace interviews, and much more. Virtual reality optional.',
    keywords: 'VR, Public, Speaking, Training'
  },
  pricing: {
    title: 'Ovation | Pricing',
    description: 'Free trial available for individuals and organizations.',
    keywords: 'VR, Public, Speaking, Training'
  },
  education: {
    title: 'Ovation | Education',
    description: '',
    keywords: ''
  },
  getStarted: {
    title: 'Ovation | Get Started',
    description: 'Step-by-step instructions to get started quickly with Ovation.',
    keywords: 'VR, Public, Speaking, Training'
  },
  faq: {
    title: 'Ovation | FAQs',
    description:
      'Revolutionary software to overcome fear of public speaking, refine presentation skills, ace interviews, and much more. Virtual reality optional.',
    keywords: 'VR, Public, Speaking, Training'
  }
}
